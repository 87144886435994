import React, {useState, useEffect} from "react"
import { navigate } from 'gatsby'
import Spinner from 'react-bootstrap/spinner'
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import SupportHelmet from 'src/components/SupportHelmet'
import { identify, trackPage, trackEvent } from 'src/utilities/analytics'
import getFirebase from 'src/utilities/firebase'
import { getUserSubscription, createCustomerPortalSession } from 'src/utilities/http-requests'
import { UserSubscriptionStatusEnum, getUserSubscriptionStatus } from 'src/utilities/user-subscription-status'
import SoftGreenCheckmarkBadge from 'src/components/shared/badges/SoftGreenCheckmarkBadge'

const pageName = 'Account'
const locationURL = `${process.env.BASE_URL}/account`

const Header = ({currentUser}) => {
  const currentUserEmail = currentUser ? currentUser.email : ''
  return (
    <header className="bg-dark pt-9 pb-11 d-md-block">
      <div className="container-md">
        <div className="row align-items-center">
          <div className="col">
            <h1 className="font-weight-bold text-white mb-2">
              Account Settings
            </h1>
            <p className="font-size-lg text-white-75 mb-0">
              Settings for {currentUserEmail}
            </p>
          </div>
        </div>
      </div>
    </header>
    )
}

const PlanLineItem = ({text}) => {
  return (
    <div className="d-flex">
      <SoftGreenCheckmarkBadge/>
      <p>
        <small>{text}</small>
      </p>
    </div>)
}

// the part of the plan card that says whether you are on free or paid plan
// with button to go to either plans or billing portal
// new users - upgrade and plans
// valid plan - manage and portal
// lapsed plan - upgrade and portal

const PlanCell = ({userSubscriptionStatus, userSubscription}) => {
  const [isLoading, setIsLoading] = useState(false)
  // new users go to plans, valid and lapsed go to billing portal
  const shouldGoToPlans = userSubscriptionStatus == UserSubscriptionStatusEnum.new
  const onClick = () => {
    if (shouldGoToPlans) {
      navigate('/plans')
    } else {
      setIsLoading(true)
      createCustomerPortalSession({
        customerId: userSubscription.customerId,
        returnURL: locationURL,
      })
      .then(session => {
        navigate(session.url)
        setIsLoading(false)
      })
      .catch(error => {
        console.error('createCustomerPortalSession error', error)
        setIsLoading(false)
      })
    }
    trackEvent('clicked_upgrade_or_manage_plan', {
      page: pageName,
      subscription_status: userSubscriptionStatus,
    })
  }
  // valid users see button that says manage, new and lapsed see button that says upgrade
  const shouldSayManage = userSubscriptionStatus == UserSubscriptionStatusEnum.valid
  const buttonTitle = shouldSayManage ? 'Manage' : 'Upgrade'
  const planName = userSubscriptionStatus == UserSubscriptionStatusEnum.valid ? 'Premium Plan' : 'Free Plan'
  const buttonContent = isLoading ? <Spinner animation="border" variant='light'/> : buttonTitle
  return (
    <div className="row mb-5">
      <div className="col">
        <h4 className="mb-0">
          {planName}
        </h4>
      </div>
      <div className="col-auto">
        <button disabled={isLoading} className="btn btn-xs btn-primary" onClick={onClick}>
          {buttonContent}
        </button>
      </div>
    </div>
    )
}

const FreePlanCard = ({userSubscriptionStatus, userSubscription}) => {
  return (
    <div className="card-body">
      <PlanCell {...{userSubscriptionStatus, userSubscription}}/>
      <PlanLineItem text='Save, edit, and preview a resume'/>
      <div className="row mb-5 mt-5">
        <div className="col">
          <h4 className='mb-0'>
            Upgrade to:
          </h4>
        </div>
      </div>
      <PlanLineItem text='Create up to 10 resumes'/>
      <PlanLineItem text='Download PDFs'/>
    </div>
    )
}

const PaidPlanCard = ({userSubscriptionStatus, userSubscription}) => {
  // this should be able to link to billing portal or plans
  // depending on user subscription status
  return (
    <div className="card-body">
      <PlanCell {...{userSubscriptionStatus, userSubscription}}/>
      <PlanLineItem text='Save up to 10 resumes'/>
      <PlanLineItem text='Unlimited PDF downloads'/>
    </div>
    )
}

const Main = ({userSubscription, userSubscriptionStatus,}) => {
  // this should show paid plan card or free plan card depending on
  // user subscription status
  const planCard = userSubscriptionStatus == UserSubscriptionStatusEnum.valid ?
                      <PaidPlanCard {...{userSubscriptionStatus, userSubscription}}/> : <FreePlanCard {...{userSubscriptionStatus, userSubscription}}/>
  return (
    <main className="pb-8 pb-md-11 mt-md-n6">
      <div className="container-md">
        <div className="row">
          <div className="col-12">
            <div className="card card-bleed shadow-light-lg mb-6">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="mb-0">
                      Current Plan
                    </h4>
                  </div>
                </div>
              </div>
              {planCard}
            </div>
          </div>
        </div>
      </div>
    </main>
    )
}

const AccountPage = () => {
  const [currentUser, setCurrentUser] = useState(null)
  const [userSubscription, setUserSubscription] = useState(null)
  const userSubscriptionStatus = getUserSubscriptionStatus({userSubscription})
  const firebase = getFirebase();
  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
     setCurrentUser(user)
     identify(user)
     user && checkAndUpdateSubscriptionStatus({userUID: user.uid})
    });
  }, [firebase]);

  useEffect(() => {
    trackPage(pageName)
  }, [])

  const checkAndUpdateSubscriptionStatus = ({userUID}) => {
    if (!userUID) return
    getUserSubscription({userUID})
    .then(userSubscription => {
      setUserSubscription(userSubscription)
    })
    .catch(error => {
      console.error(error)
    })
  }

  const containerStyle = {
    width: '100%',
    height: '100%',
  }
  return (
    <Layout
      shouldForceLoginModalOpenIfNoCurrentUser={true}
    >
      <AnalyticsHelmet/>
      <SupportHelmet/>
      <SEO title={pageName} />
      <div style={containerStyle}>
        <Header {...{currentUser}}/>
        <Main {...{userSubscription, userSubscriptionStatus}}/>
      </div>
    </Layout>
  )
}

export default AccountPage
