const UserSubscriptionStatusEnum = Object.freeze({
  new: 'new',
  lapsed: 'lapsed',
  valid: 'valid',
})
const getUserSubscriptionStatus = ({userSubscription}) => {
  if (!userSubscription) {
    return UserSubscriptionStatusEnum.new
  }
  const { timeRemaining } = userSubscription
  if (timeRemaining > 0) {
    return UserSubscriptionStatusEnum.valid
  }
  return UserSubscriptionStatusEnum.lapsed
}

export { UserSubscriptionStatusEnum, getUserSubscriptionStatus }
